import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: "home",
      component: () => import("@/views/home/Index.vue"),
      meta: {
        title: '首页',
        // keepAlive: true
      }
    }, {
      path: '/home',
      name: "home",
      component: () => import("@/views/home/Index.vue"),
      meta: {
        title: '首页',
        // keepAlive: true
      }
    },
       {
      path: '/login',
      name: "login",
      component: () => import("@/views/login/index.vue"),
      meta: {
        title: '登录',
        // keepAlive: true
      }
    },    {
      path: '/login/findPassword',
      name: "findpassword",
      component: () => import("@/views/login/findPassword"),
      meta: {
        title: '找回密码',
        // keepAlive: true
      }
    },{
      path: '/login/register',
      name: "register",
      component: () => import("@/views/login/register"),
      meta: {
        title: '账号注册',
        // keepAlive: true
      }
    },{
      path: '/login/companyAccount',
      name: "companyAccount",
      component: () => import("@/views/login/companyAccount"),
      meta: {
        title: '公司账号注册',
        // keepAlive: true
      }
    },{
      path: '/login/departmentAccount',
      name: "departmentAccount",
      component: () => import("@/views/login/departmentAccount"),
      meta: {
        title: '部门账号注册',
        // keepAlive: true
      }
    },
       {
      path: '/home/aboutUs',
      name: "aboutUs",
      component: () => import("@/views/home/aboutUs"),
      meta: {
        title: '关于我们',
        // keepAlive: true
      }
    },
    {
      path: '/login/yinsi',
      name: "yinsi",
      component: () => import("@/views/login/yinsi"),
      meta: {
        title: '隐私协议',
        // keepAlive: true
      }
    },
       {
      path: '/home/feedBack',
      name: "feedBack",
      component: () => import("@/views/home/feedBack"),
      meta: {
        title: '意见反馈',
        // keepAlive: true
      }
    },{
      path: '/home/privacyPolicy',
      name: "privacyPolicy",
      component: () => import("@/views/home/privacyPolicy"),
      meta: {
        title: '隐私政策',
        // keepAlive: true
      }
    },{
      path: '/home/userAgreement',
      name: "userAgreement",
      component: () => import("@/views/home/userAgreement"),
      meta: {
        title: '用户协议',
        // keepAlive: true
      }
    },
       {
      path: '/home/contactAs',
      name: "contactAs",
      component: () => import("@/views/home/contactAs"),
      meta: {
        title: '联系我们',
        // keepAlive: true
      }
    },{
      path: '/home/userManual',
      name: "userManual",
      component: () => import("@/views/home/userManual"),
      meta: {
        title: '用户手册',
        // keepAlive: true
      }
    },
    {
      path: '/home/productDetail',
      name: "productDetail",
      component: () => import("@/views/home/productDetail"),
      meta: {
        title: '商品详情',
        // keepAlive: true
      }
    },
    {
      path: '/home/payBook',
      name: "payBook",
      component: () => import("@/views/home/payBook"),
      meta: {
        title: '付款通知书',
        // keepAlive: true
      }
    },
    {
      path: '/home/excelList',
      name: "excelList",
      component: () => import("@/views/home/excelList"),
      meta: {
        title: '下载订单列表',
        // keepAlive: true
      }
    },
    {
      path: '/home/balance',
      name: "balance",
      component: () => import("@/views/home/Balance"),
      meta: {
        title: '结算',
        keepAlive: true
      }
    },
    {
      path: "/mine/orderdetail",
      name: "Orderdetail",
      component: () => import("@/views/mine/components/Orderdetail"),
      meta: {
        title: "订单详情"
      }
    },
    {
      path: '/mine',
      name: 'Mine',
      component: () => import("@/views/mine/index"),
      meta: { 
        title: '帐号中心',
        // keepAlive: true
      },
      children: [
        { path: '/mine', redirect: '/mine/account' },
        {
          path: '/mine/account',
          name: "MineAccount",
          component: () =>
            import("@/views/mine/components/MyAccount"),
          meta: {
            title: "我的账户",
          //  keepAlive: true

          }
        },
        {
          path: "/mine/address",
          name: "MineAddress",
          component: () => import("@/views/mine/components/Address"),
          meta: {
            title: "地址"
          }
        },
        {
          path: "/mine/changepassword",
          name: "Changepassword",
          component: () => import("@/views/mine/components/Changepassword"),
          meta: {
            title: "修改密码"
          }
        },
         {
          path: "/mine/orderlist",
          name: "OrderList",
          component: () => import("@/views/mine/components/OrderList"),
          meta: {
            title: "订单列表"
          }
        },
      ]
    }
    
   
  ]
})



export default router